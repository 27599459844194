<template>
  <div>
    <section class="section is-main-section">
      <finance-table ref="table" :settings="table.settings" :filter="table.filter" :page="table.page" :pagination="table.pagination"/>
    </section>
  </div>
</template>

<script>
import ClientsTableSample from '@/components/ClientsTableSample'
import CardComponent from '@/components/CardComponent'
import RemoveModal from '@/components/RemoveModal'
import { BankService as Service } from '@/services'

export default {
  name: 'Tables',
  components: {
    CardComponent,
    ClientsTableSample,
  },
  data () {
    return {
      table: {
        page: {
          title: 'Ödeme Yöntemi Listesi',
          icon: 'bank',
          actions: [
            {
              title: 'Ödeme Yöntemi Oluştur',
              type: 'success',
              icon: 'plus',
              action: () => { this.$router.push({ name: 'paymentMethodCreateOrUpdate' }) },
              perm: 'PaymentMethod.Create'
            },
            {
              title: 'Filtrele',
              type: 'info',
              icon: 'sync',
              action: () => { this.$refs.table.loadAsyncData() }
            }
          ]
        },
        settings: {
          service: Service,
          method: 'list',
          columns: [
            {
              field: 'name',
              label: 'Ödeme Yöntemi',
              sortable: true
            },
            {
              field: 'type',
              label: 'Tür',
              sortable: true,
              viewMethod: val => this.enums.bankTypes[val]
            },
            {
              field: 'status',
              label: 'Durum',
              sortable: true,
              renderHtml: true,
              viewMethod: val => `<span class="tag ${val ? 'is-success' : 'is-danger'}">${val ? 'Aktif' : 'Pasif'}</span>`
            },
            {
              field: 'createdAt',
              label: 'Kayıt T.',
              sortable: true,
              viewMethod: val => this.dateFormat(val, this.dateFormats.normalDate)
            },
            {
              field: 'actions',
              column: 'id',
              label: 'İşlemler',
              sortable: false,
              width: '100px',
              actions: [
                {
                  title: 'Düzenle',
                  type: 'success',
                  column: 'row',
                  perm: 'PaymentMethod.Edit',
                  action: data => this.$router.push({ name: 'paymentMethodCreateOrUpdate', params: { id: data.uuid } })
                },
                {
                  column: 'row',
                  title: 'Sil',
                  type: 'danger',
                  perm: 'PaymentMethod.Delete',
                  action: data => {
                    this.openModal({
                      component: RemoveModal,
                      props: {
                        id: data.uuid,
                        name: data.name,
                        service: data.service,
                        type: 'Ödeme Yöntemi'
                      },
                      close: this.$refs.table.loadAsyncData
                    })
                  }
                }
              ]
            }
          ]
        },
        pagination:{
          paginate: 50,
          page: 1,
          sortField: 'status',
          sortOrder: 'desc',
          defaultSortOrder: 'desc'
        },
        filter: [
          {
            type: 'input',
            field: 'name',
            name: 'Ödeme Yöntemi'
          },
          {
            type: 'switch',
            value: true,
            field: 'status',
            name: 'Durum'
          }
        ]
      }
    }
  }
}
</script>
